<template>
    <div class="container my-5">
        <h5 class="text-center">404 Page not found</h5>
    </div>
</template>

<script>
export default {}
</script>

<style></style>
